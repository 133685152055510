import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
const url = process.env.REACT_APP_API_URL;
const empId = localStorage.getItem('_id') || "";
const token = localStorage.getItem('token');

const updateDataAPI = async (body) => {
    const clockinsId = localStorage.getItem('clockinsId') || "";

    try {
        const response = await axios.put(`${url}/api/clock-ins/${clockinsId}`, body, {
            headers: { authorization: token || '' },
        });
        console.log('Updated successfully:', response.data);
    } catch (error) {
        console.error('Update error:', error);
    }
};

const getDataAPI = async (id) => {
    try {
        const response = await axios.get(`${url}/api/clock-ins/${id}`, {
            headers: { authorization: token || '' },
        });

        const data = response.data;
        data.timeData.meeting.takenTime = 0; // Do this before setting the state to avoid mutation
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
    }
};

const addDataAPI = async (body) => {
    try {
        const response = await axios.post(`${url}/api/clock-ins/${empId}`, body, {
            headers: { authorization: token || '' },
        });
        localStorage.setItem('clockinsId', response.data._id);
        console.log('Added successfully:', response.data);
    } catch (error) {
        toast.error(`Data not added: ${error.message}`);
    }
};

function removeClockinsData() {
    localStorage.removeItem('countdownEndTime');
    localStorage.removeItem('timeOption');
    localStorage.removeItem('isPaused');
    localStorage.removeItem('clockinsId');
}

const fetchLeaveRequests = async (empId) => {
    try {
        const res = await axios.get(`${url}/api/leave-application/emp/${empId}`, {
            headers: {
                authorization: token || ""
            }
        });
        return res.data;
    } catch (err) {
        if (err.response && err.response.data && err.response.data.details) {
            toast.error(err.response.data.details)
        }
    }
};


async function deleteLeave(id) {
    try {
        let deletedMsg = await axios.delete(`${url}/api/leave-application/${empId}/${id}`, {
            headers: {
                authorization: token || ""
            }
        })

        toast.success(deletedMsg.data.message);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message)
        }
    }
}

const fetchEmployeeData = async (id) => {
    try {
        const response = await axios.get(`${url}/api/employee/${id}`, {
            headers: {
                authorization: token || ""
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            console.error("Error fetching employee data:", error);
        }
    }
};

const fetchEmployees = async () => {
    try {
        const res = await axios.get(`${url}/api/employee`, {
            headers: {
                authorization: token || ""
            }
        });
        return res.data;
    } catch (err) {
        console.log(err);
        if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message)
        }
    }
}

export {
    addDataAPI,
    getDataAPI,
    updateDataAPI,
    removeClockinsData,
    fetchLeaveRequests,
    deleteLeave,
    fetchEmployeeData,
    fetchEmployees
};
