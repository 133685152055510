import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import PayslipParent from './component/payslip/PayslipParent';
import Employee from './component/payslip/Employee';

ReactDOM.render(
    <BrowserRouter>
      {/* <App /> */}
      {/* <PayslipParent /> */}
      <Employee />
    </BrowserRouter>,
    document.getElementById('root')
  );




