import React, { useState } from 'react'
import Parent from '../Parent'
import Attendence from "./Attendence";
import Leave from "./Leave";
import Folder from "./Folder";
import Assets from "./Assets";
import Payrun from "./Payrun";
import Payslip from "./Payslip";
import Address from "./Address";
import Contact from "./Contact";
import Social from "./Social";
import History from "./History";
import Salary from "./Salary";
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard';
import Settings from '../Settings/Settings';
import JobDesk from './Jobdesk';
import Employee from './Employee';
import Administration from './Administration';

export default function HRMDashboard({ whoIs }) {

    return (
        <Routes >
            <Route path="/" element={<Parent whoIs={whoIs} />} >
                <Route index element={<Dashboard />} />
                <Route path="job-desk/" element={<JobDesk />} />
                <Route path="employee/" element={<Employee />} />
                <Route path="leave/" element={<Leave />} />
                <Route path="attendence/" element={<Attendence />} />
                <Route path="administration/" element={<Administration />} />
                <Route path="settings/" element={<Settings />} />
            </Route>
        </Routes>
    )
}
