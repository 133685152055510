import React, { useEffect, useState, useContext } from "react"
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import "./ParentStyle.css";
import SideBar from "./SideBar";
import ClockIns from "./EmployeeHub/ClockIns";
import { createContext } from "react";
import { EssentialValues } from "../App";
import { getDataAPI } from "./EmployeeHub/ClockInsApi";

export const WorkTimeTrackerContext = createContext(null);

const Parent = ({ whoIs }) => {
    const { data, handleLogout } = useContext(EssentialValues);
    const [isAction, setIsAction] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const isPaused = localStorage.getItem("isPaused");
    const currentDate = new Date();
    const today = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

    // const startAndEndTime = {
    //     startingTime: '00:00',
    //     endingTime: '00:00',
    //     takenTime: 0,
    //     timeHolder: 0,
    // };
    // const [workTimeTracker, setWorkTimeTracker] = useState({
    //     date: today,
    //     login: {
    //         startingTime: '00:00',
    //         endingTime: '00:00',
    //         takenTime: 0,
    //         timeHolder: 0,
    //     },
    //     meeting: {
    //         startingTime: '00:00',
    //         endingTime: '00:00',
    //         takenTime: 0,
    //         timeHolder: 0
    //     },
    //     morningBreak: {
    //         startingTime: '00:00',
    //         endingTime: '00:00',
    //         takenTime: 0,
    //         timeHolder: 0
    //     },
    //     lunch: { ...startAndEndTime },
    //     eveningBreak: { ...startAndEndTime },
    //     event: { ...startAndEndTime }
    // });

    const handleActions = () => {
        setIsAction(!isAction);
    };

    function handleSideBar() {
        setSideBar(!sideBar)
    }

    // useEffect(() => {
    //     // debugger;
    //     console.log(getDataAPI());
    //     setWorkTimeTracker(getDataAPI())
    // }, [])

    // useEffect(() => {
    //     const account = localStorage.getItem("Account");
    //     if (account == 1) {
    //         setHowIs("/admin")
    //     } else if (account == 2) {
    //         setHowIs("/hr")
    //     } else if (account == 3) {
    //         setHowIs("/emp")
    //     }
    // }, [])
    return (
        <div className="d-flex">
            {/* <SideBar onLogout={handleLogout} whoIs={whoIs} empData={data} className={sideBar ? "active" : "empty"} handleActions={handleActions} handleSideBar={handleSideBar} isAction={isAction} /> */}
            <NavBar whoIs={whoIs} isAction={isAction} handleSideBar={handleSideBar} empData={data} handleActions={handleActions} />
            <div className="navContent">
                {/* <WorkTimeTrackerContext.Provider value={{ workTimeTracker, setWorkTimeTracker }}> */}
                {/* <ClockIns updateClockins={updateClockins} /> */}
                <SideBar onLogout={handleLogout} whoIs={whoIs} empData={data} className={sideBar ? "active" : "empty"} handleActions={handleActions} handleSideBar={handleSideBar} isAction={isAction} />
                <Outlet whoIs={whoIs} />
                {/* </WorkTimeTrackerContext.Provider> */}
            </div>
        </div>
    )
};

export default Parent;
