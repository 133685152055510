import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import axios from "axios";
import jwt from "jsonwebtoken";
import Login from "./component/Login.jsx";
// import DashboardAdmin from "./component/admin/DashboardAdmin.jsx";
// import DashboardHR from "./component/hr/DashboardHR.jsx";
// import DashboardEmployee from "./component/employee/DashboardEmployee.jsx";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Layout from "./component/Layout.jsx";
import Attendence from "./component/payslip/Attendence.jsx";
import HRMDashboard from "./component/payslip/HRMDashboard.jsx";
import PayslipParent from "./component/payslip/Jobdesk.jsx";
import JobDesk from "./component/payslip/Jobdesk.jsx";
// import NotFound404 from "./component/NotFound404.jsx";

export const EssentialValues = createContext(null);
const App = () => {
  const [data, setData] = useState({
    _id: localStorage.getItem("_id") || "",
    Account: localStorage.getItem("Account") || "",
    Name: localStorage.getItem("Name") || ""
  });
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState(true);
  const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin") === "true");
  const [whoIs, setWhoIs] = useState("");
  const navigate = useNavigate();

  const handleSubmit = event => {
    event.preventDefault();
    setPass(true);
    setLoading(true);
    login(event.target[0].value, event.target[1].value);
    event.target.reset();
  };

  const handleLogout = () => {
    console.log("logout clicked!");
    if (localStorage.getItem('empId')) {
      toast.warn(`Please Enter full details for this employee`);
    } else if (localStorage.getItem('isPaused') === "false") {
      toast.warn("you can't logout until timer stop.")
    } else {
      console.log("logout");
      localStorage.clear();
      setData({
        _id: "",
        Account: "",
        Name: ""
      });
      setIsLogin(false);
      navigate("/login")
    }

  };

  const login = (id, pass) => {
    let bodyLogin = {
      Email: id,
      Password: pass
    };
    console.log(bodyLogin);
    axios
      .post(process.env.REACT_APP_API_URL + "/api/login", bodyLogin)
      .then(res => {
        var decodedData = jwt.decode(res.data);
        localStorage.setItem("token", res.data);

        if (
          (res == undefined ||
            res == null ||
            decodedData.Account == undefined ||
            decodedData.Account == null) &&
          !(
            decodedData.Account == 1 ||
            decodedData.Account == 2 ||
            decodedData.Account == 3
          )
        ) {
          setPass(false);
          setLoading(false);
        } else {
          const accountType = decodedData.Account;
          setData({
            _id: decodedData._id,
            Account: accountType,
            Name: `${decodedData.FirstName} ${decodedData.LastName}`
          });
          setPass(true);
          setLoading(false);
          setIsLogin(true);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("Account", accountType);
          localStorage.setItem("_id", decodedData._id);
          localStorage.setItem("Name", `${decodedData.FirstName} ${decodedData.LastName}`);
          localStorage.setItem("annualLeaveEntitment", decodedData.annualLeaveEntitlement);

          if (accountType == 1) {
            setWhoIs("admin");
            navigate("/admin");
          } else if (accountType == 2) {
            setWhoIs("hr")
            navigate("/hr");
          } else if (accountType == 3) {
            setWhoIs("emp");
            navigate("/emp");
          }
        }
      })
      .catch(err => {
        console.log(err);
        setPass(false);
        setLoading(false);
      });
  };

  return (
    <EssentialValues.Provider value={{ data, handleLogout, handleSubmit, loading, pass }}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route path="login" element={<Login />} />
          <Route path="admin/*" element={<HRMDashboard whoIs={whoIs}/>} />
          <Route path="hr/*" element={<HRMDashboard whoIs={whoIs} />} />
          <Route path="emp/*" element={<HRMDashboard whoIs={whoIs}/>} />
          {/*  <Route path="job-desk/*" element={<JobDesk />} /> */}
          {/* <Route path="admin/*" element={<DashboardAdmin />} />
          <Route path="hr/*" element={<DashboardHR />} />
          <Route path="emp/*" element={<DashboardEmployee data={data} />} /> */}
          <Route path="*" element={<Layout />} />
        </Route>
      </Routes>
    </EssentialValues.Provider>
  );
};

export default App;